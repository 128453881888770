import { useEffect, useState } from 'react'
import axios from 'axios'
import { getConfig } from '../config/getConfig'
import Spotify from '../images/svg/Spotify'
import backgroundImage from '../images/chasing-shadows-bg-blur.webp'
import '../singles.css'
import '../EmailForm.css'; // Import CSS file for styling
const config = getConfig()

const link = {
    id: 'chasing-shadows',
    title: 'Our latest single "Chasing Shadows"',
    band_name: 'Her Last Sight',
    song_name: 'Chasing Shadows',
    type: 'multi',
    links: {
        youtube: 'https://www.youtube.com/watch?v=0jCn-CW9ycY',
        spotify: '',
        applemusic: '',
        itunes: '',
        deezer: '',
        tidal: '',
        youtubemusic: '',
        amazon: '',
        amazonmusic: '',
        boomplay: '',
        pandora: '',
        anghami: '',
    },
    image: 'https://d1fdloi71mui9q.cloudfront.net/OwzhWSgSRUWWa4KC8AER_10A4897B-90B9-4E35-93F3-0333025BEC44.png'
}

export default function PreSaveChasingShadowsSpotify({ success }) {
    const [name, setname] = useState('');
    const [email, setemail] = useState('');

    const [shareEmail, setshareEmail] = useState(true);

    const [showTermsOfService, setshowTermsOfService] = useState(false);

    const searchParams = new URLSearchParams(window.location.search);
    const paramsObject = {};
    for (let [key, value] of searchParams) { paramsObject[key] = value; }

    async function countVisitors() {
        try {
            axios.get(`${config.backend}/countVisitors?o=${paramsObject.o}&p=chasing-shadows-presave`)
        } catch (error) { }
    }
    useEffect(() => {
        countVisitors()

    }, [])

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (email.includes('.') && email.includes('@')) {
            const pre_save_data = { email, name, o: paramsObject.o }
            try {
                await axios.post(`${config.backend}/manual_pre_save`, pre_save_data)
                window.location.href = '/chasing-shadows/success'

            } catch (error) {
                alert("Failed.")
            }
        }
        else {
            alert("Email is invalid.")
            return false
        }
    };


    return (<div className='bg_full_single' style={{ backgroundImage: `url(${backgroundImage})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'center center', justifyContent: 'space-evenly', }}>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '1vh', marginBottom: '3vh' }}>
            <img src="https://static.wixstatic.com/media/b59144_9ede0b40946b43e68439cb4a5b18ccf1~mv2.png/v1/fill/w_775,h_189,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/LOGO%20STRAIGHT_edited.png" style={{ maxWidth: "80%", height: 'auto' }} alt="" />
            <span className='single_name'>{link.song_name.toUpperCase()}</span>
        </div>

        <div className='presave_success' style={{ display: 'flex', flexDirection: 'column' }}>
            <img src="https://distrokid.imgix.net/http%3A%2F%2Fgather.fandalism.com%2F2417413--16D8F093-0607-4232-BCA71090870F69D8--0--5568406--ChasingShadows.png?fm=jpg&q=75&w=800&s=1488d8a76c771fc01de012fdb9022124" style={{ width: "300px", height: 'auto', boxShadow: "rgb(255 255 255 / 10%) 0px 2px 20px 0px" }} alt="" />
            <div style={{ marginTop: '1vh' }}>
                Available on June 30th
            </div>
        </div>

        {!success && <a className='presave_button' href={`${config.backend}/spotify/login?shareEmail=${shareEmail}`}>
            <div style={{ display: 'flex', alignItems: 'center', width: "100%", justifyContent: 'space-between' }}>Spotify Pre-Save <Spotify width={25} height={25} /></div>
            <div style={{ fontSize: '10px', marginTop: "10px", display: 'flex', alignItems: 'center' }} >
                {/* (I consent to share my email and name with HLS.) */}
                <input type='checkbox' checked={shareEmail} onChange={e => setshareEmail(!shareEmail)} />
                Share email address with artist
            </div>
        </a>}

        {/* NO SPOTIFY API */}
        {/* <div className='presave_success' style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ marginBottom: '1vh' }}>
                Available on June 30th
            </div>
            <img src="https://distrokid.imgix.net/http%3A%2F%2Fgather.fandalism.com%2F2417413--16D8F093-0607-4232-BCA71090870F69D8--0--5568406--ChasingShadows.png?fm=jpg&q=75&w=800&s=1488d8a76c771fc01de012fdb9022124" style={{ width: "300px", height: 'auto', boxShadow: "rgb(255 255 255 / 10%) 0px 2px 20px 0px" }} alt="" />
        </div>

        {!success && <div className="email-form">
            <form onSubmit={handleSubmit}>
                <label style={{ fontSize: 'normal', marginBottom: '2vh', textAlign: 'center' }}>Stay informed via email when songs release:</label>
                <input type="text" autoComplete="name" placeholder="Enter Full Name" className="input-field" value={name} onChange={(e) => setname(e.target.value)} required />
                <input type="email" autoComplete="email" placeholder="Enter Email Address" className="input-field" value={email} onChange={(e) => setemail(e.target.value)} required />
                <button type="submit" className="submit-button">Submit</button>
                <label style={{ fontSize: '10px', marginTop: '1vh', textAlign: 'center', display: 'flex', gap: '5px' }}>By submitting, I agree to the <div style={{ color: 'blue', textDecoration: 'underlines', cursor: 'pointer' }} onClick={e => setshowTermsOfService(true)}>terms of service.</div></label>
            </form>
        </div>}
        {showTermsOfService && <div onClick={e => setshowTermsOfService(false)} style={{ position: 'absolute', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#232323d9', width: '100%', height: "100%" }}>
            <div style={{ position: 'absolute', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'white', borderRadius: '30px', color: 'black', padding: '20px', width: '80%' }}>
                I give my consent to share my email and name with Her Last Sight and acknowledge that I will receive emails.
            </div>
        </div>} */}

        {success && <div className='presave_success'>
            Thank you for Pre-Saving "{link.song_name.toUpperCase()}"!<br />
            You'll be notified by email when the song is available for streaming!
        </div>}
        {/* NO SPOTIFY API */}

        <footer style={{}}>
            Her Last Sight 2023 ©
        </footer>
    </div>)
}

