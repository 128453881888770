import { useEffect, useState } from 'react'
import axios from 'axios'
import { getConfig } from '../config/getConfig'
import Spotify from '../images/svg/Spotify'
import Applemusic from '../images/svg/Applemusic'
import Youtube from '../images/svg/Youtube'
import Amazon from '../images/svg/Amazon'
import AmazonMusic from '../images/svg/AmazonMusic'
import Anghami from '../images/svg/Anghami'
import Boomplay from '../images/svg/Boomplay'
import Deezer from '../images/svg/Deezer'
import Itunes from '../images/svg/Itunes'
import Pandora from '../images/svg/Pandora'
import Tidal from '../images/svg/Tidal'
import YoutubeMusic from '../images/svg/YoutubeMusic'
import backgroundImage from '../images/chasing-shadows-bg.webp'
import '../singles.css'
const config = getConfig()

const link = {
    id: 'chasing-shadows',
    title: 'Our latest single "Chasing Shadows"',
    band_name: 'Her Last Sight',
    song_name: 'Chasing Shadows',
    type: 'multi',
    links: {
        spotify: 'https://open.spotify.com/track/2MuE75WMYUOtEty5S4vPnW?si=fd2507052d9d46a9',
        applemusic: 'https://music.apple.com/il/album/chasing-shadows/1690090936?i=1690090937',
        youtube: 'https://www.youtube.com/watch?v=0jCn-CW9ycY',
        deezer: 'https://deezer.page.link/9pAejzJazjMh2uUBA',
        youtubemusic: 'https://music.youtube.com/watch?v=x67RlgV-H8I',
        itunes: 'https://music.apple.com/us/album/chasing-shadows-single/1690090936?uo=4&app=itunes&at=1001lry3&ct=dashboard',
        // tidal: '',
        // amazon: '',
        // amazonmusic: '',
        // boomplay: '',
        // pandora: '',
        // anghami: '',
    },
    image: 'https://d1fdloi71mui9q.cloudfront.net/OwzhWSgSRUWWa4KC8AER_10A4897B-90B9-4E35-93F3-0333025BEC44.png'
}

const bandLogo = "https://d1fdloi71mui9q.cloudfront.net/fdFoydmBRp6CmAJOe9e2_wyQ5H6M5AHHfFX0Q"

export default function SingleChasingShadows() {
    const urlParams = new URLSearchParams(window.location.search);
    const paramsObject = {};
    for (let [key, value] of urlParams) { paramsObject[key] = value; }

    const queryString = [...urlParams].map(([key, value]) => `${key}=${value}`).join('&');

    async function countVisitors() {
        try {
            // axios.get(`${config.backend}/countVisitors?o=${paramsObject.o}&p=chasing-shadows`)
            axios.get(`${config.backend}/e/view?${queryString}`)
        } catch (error) { }
    }

    async function sendClick(event, data, platform = null) {
        event.preventDefault();

        const dataCopy = JSON.parse(JSON.stringify(data))
        try {
            if (data.type === 'multi') {
                dataCopy.link = data.links[platform]
                delete dataCopy.links
                dataCopy.platform = platform
            }
            const body = { ...paramsObject, clicked_link: encodeURIComponent(dataCopy.link), platform: dataCopy.platform }
            let queryString_click = ""
            Object.keys(body).forEach(key => { queryString_click += key + '=' + body[key] + "&" })
            queryString_click = queryString_click.slice(0, queryString_click.length - 1)
            await axios.get(`${config.backend}/e/click?${queryString_click}`)
        } catch (error) { }
        finally {
            window.location.href = data.links[platform];
        }
    }

    useEffect(() => {
        countVisitors()
    }, [])

    return (<div className='bg_full_single' style={{ backgroundImage: `url(${backgroundImage})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'center center', }}>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '1vh', marginBottom: '3vh' }}>
            <img src="https://static.wixstatic.com/media/b59144_9ede0b40946b43e68439cb4a5b18ccf1~mv2.png/v1/fill/w_775,h_189,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/LOGO%20STRAIGHT_edited.png" style={{ maxWidth: "80%", height: 'auto' }} alt="" />
            <span className='single_name'>{link.song_name.toUpperCase()}</span>
        </div>

        {Object.keys(link.links).map(platform => <a className="singleLink_single" onClick={e => { sendClick(e, link, platform) }} href={link.links[platform]}>
            {getPlatformLogo(platform, 45, 45)}
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                <div style={{ marginLeft: '-7%' }}>{getPrettyName(platform)}</div>
            </div>
        </a>)}

        <footer style={{}}>
            Her Last Sight 2023 ©
        </footer>
    </div>)
}

function getPlatformLogo(platform, width, height) {
    switch (platform) {
        case 'amazonmusic':
            return <AmazonMusic width={width} height={height} />
        case 'amazon':
            return <Amazon width={width} height={height} />
        case 'anghami':
            return <Anghami width={width} height={height} />
        case 'applemusic':
            return <Applemusic width={width} height={height} />
        case 'boomplay':
            return <Boomplay width={width} height={height} />
        case 'deezer':
            return <Deezer width={width} height={height} />
        case 'itunes':
            return <Itunes width={width} height={height} />
        case 'pandora':
            return <Pandora width={width} height={height} />
        case 'spotify':
            return <Spotify width={width} height={height} />
        case 'tidal':
            return <Tidal width={width} height={height} />
        case 'youtubemusic':
            return <YoutubeMusic width={width} height={height} />
        case 'youtube':
            return <Youtube width={width} height={height} />
        default:
            return ""
    }
}
function getPrettyName(platform) {
    switch (platform) {
        case 'amazonmusic':
            return "Amazon Music"
        case 'amazon':
            return "Amazon"
        case 'anghami':
            return "Anghami"
        case 'applemusic':
            return "Apple Music"
        case 'boomplay':
            return "Boomplay"
        case 'deezer':
            return "Deezer"
        case 'itunes':
            return "iTunes"
        case 'pandora':
            return "Pandora"
        case 'spotify':
            return "Spotify"
        case 'tidal':
            return "Tidal"
        case 'youtubemusic':
            return "YouTube Music"
        case 'youtube':
            return "YouTube"
        default:
            return ""
    }
}