import axios from 'axios';
import { getConfig } from '../config/getConfig';
import { useEffect, useState } from 'react';
import { Line } from "react-chartjs-2";
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import { Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, TextField, Box } from "@mui/material";
import { format, getDay } from 'date-fns'

// Register Chart.js components
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const config = getConfig();

export default function Dashboard({ loggedUser, setLoading }) {
    const [minMaxStartDate, setminMaxStartDate] = useState(format(new Date(), 'yyyy-MM-dd'));
    const [minMaxListenersData, setMinMaxListenersData] = useState([]);
    const [selectedDate, setSelectedDate] = useState(format(new Date(), 'yyyy-MM-dd'));
    const [dailyListenersData, setDailyListenersData] = useState([]);
    const [sort, setSort] = useState({ prop: '', dir: '' })

    async function loadDailyData() {
        const resp = await axios.get(`${config.backend}/db/dailyListeners?day=${selectedDate}`);
        setDailyListenersData(resp.data);
    }

    async function loadMinMaxData() {
        try {
            const resp = await axios.get(`${config.backend}/db/minMaxListeners?day=${minMaxStartDate}`);
            resp.data.forEach(row => {
                const calculatedRev = !isNaN(row.spotifyDailyStreams) ? Math.ceil(row.spotifyDailyStreams * 0.003) : Math.ceil(row.predicted_spotify_streams * 0.003)
                row.revenue = calculatedRev
            })
            setMinMaxListenersData(resp.data.reverse());
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }

    useEffect(() => {
        async function loadAll() {
            setLoading(true)
            await loadDailyData();
            await loadMinMaxData();
            setLoading(false)
        }
        loadAll()
    }, []);

    function handleSort(prop) {
        // Determine the new sorting direction
        const newDir = sort.prop === prop && sort.dir === "asc" ? "desc" : "asc";

        // Sort the data based on the selected property and direction
        const sortedData = [...minMaxListenersData].sort((a, b) => {
            if (a[prop] < b[prop]) return newDir === "asc" ? -1 : 1;
            if (a[prop] > b[prop]) return newDir === "asc" ? 1 : -1;
            if (newDir === "asc") {
                return 1
            }
            else { 
                return -1
            }
        });

        // Update the state with the sorted data and new sorting parameters
        setMinMaxListenersData(sortedData);
        setSort({ prop, dir: newDir });
    }


    const minMaxData = {
        labels: minMaxListenersData.map(item => item.date),
        datasets: [
            {
                label: "Max Listeners",
                data: minMaxListenersData.map(item => item.max_listeners),
                borderColor: "rgba(75, 192, 192, 1)",
                backgroundColor: "rgba(75, 192, 192, 0.2)",
                fill: true,
            },
            {
                label: "Min Listeners",
                data: minMaxListenersData.map(item => item.min_listeners),
                borderColor: "rgba(255, 99, 132, 1)",
                backgroundColor: "rgba(255, 99, 132, 0.2)",
                fill: true,
            },
            {
                label: "Average",
                data: minMaxListenersData.map(item => item.average),
                borderColor: "rgba(130, 64, 100, 1)",
                backgroundColor: "rgba(170, 150, 100, 0.2)",
                fill: true,
            },
        ],
    };

    const dailyData = {
        labels: dailyListenersData.map(item => item.timestamp.split("T")[1]).filter((_, index) => index % 2 === 0),
        datasets: [
            {
                label: "Listeners",
                data: dailyListenersData.map(item => item.listeners).filter((_, index) => index % 2 === 0),
                borderColor: "rgba(75, 192, 192, 1)",
                backgroundColor: "rgba(75, 192, 192, 0.2)",
                fill: true,
            },
        ],
    };

    useEffect(() => {
        loadDailyData();
    }, [selectedDate]);

    useEffect(() => {
        loadMinMaxData();
    }, [minMaxStartDate]);

    return (
        <Box sx={{ padding: "20px", maxWidth: "1600px", margin: "auto" }}>
            <Typography variant="h4" gutterBottom align="center">Dashboard</Typography>

            <Grid container spacing={4}>
                {/* MinMax Listeners Over Time Chart */}
                <Grid item xs={12}>
                    <Paper elevation={3} sx={{ padding: "20px" }}>
                        <Typography variant="h5" gutterBottom>MinMax Listeners Over Time</Typography>
                        <Box sx={{ display: 'flex', justifyContent: 'center', height: '500px' }}>
                            <Line data={minMaxData} />
                        </Box>
                    </Paper>
                </Grid>

                {/* MinMax Listeners Data Table */}
                <Grid item xs={12}>
                    <Paper elevation={3} sx={{ padding: "20px" }}>
                        <Typography variant="h5" gutterBottom>MinMax Data - (showing 30 days from date below) - {minMaxListenersData.map(i => i.revenue).reduce((a, v) => a += v, 0)}$</Typography>
                        <TextField
                            label="Select Start Data"
                            type="date"
                            value={minMaxStartDate}
                            onChange={(e) => setminMaxStartDate(e.target.value)}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            sx={{ marginBottom: "20px" }}
                        />
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell onClick={() => handleSort('date')}>Weekday</TableCell>
                                        <TableCell onClick={() => handleSort('date')}>Date</TableCell>
                                        <TableCell onClick={() => handleSort('max_listeners')}>Max Listeners</TableCell>
                                        <TableCell onClick={() => handleSort('average')}>Average</TableCell>
                                        <TableCell onClick={() => handleSort('streams_per_avg_listener')}>Streams / AVG Listener</TableCell>
                                        <TableCell onClick={() => handleSort('predicted_spotify_streams')}>Predicted Spotify Streams</TableCell>
                                        <TableCell onClick={() => handleSort('spotifyDailyStreams')}>Actual Spotify Daily Streams</TableCell>
                                        <TableCell onClick={() => handleSort('monthly_listeners')}>Monthly Listeners</TableCell>
                                        <TableCell onClick={() => handleSort('followers')}>Followers</TableCell>
                                        <TableCell onClick={() => handleSort('revenue')}>MONEY EARNED</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {minMaxListenersData.map((row, index) => (
                                        <TableRow key={index} hover>
                                            <TableCell>{getHebrewDays(getDay(row.date))}</TableCell>
                                            <TableCell>{format(new Date(row.date), 'dd-MM-yyyy')}</TableCell>
                                            <TableCell>{row.max_listeners}</TableCell>
                                            <TableCell>{row.average}</TableCell>
                                            <TableCell>{row.streams_per_avg_listener}</TableCell>
                                            <TableCell>{row.predicted_spotify_streams}</TableCell>
                                            <TableCell>{row.spotifyDailyStreams}</TableCell>
                                            <TableCell>{row.monthly_listeners}</TableCell>
                                            <TableCell>{row.followers}</TableCell>
                                            <TableCell>{row.revenue} $</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>
                </Grid>

                {/* Daily Listeners Section */}
                <Grid item xs={12}>
                    <Paper elevation={3} sx={{ padding: "20px", marginBottom: "20px" }}>
                        <Typography variant="h5" gutterBottom>Daily Data - {selectedDate}</Typography>

                        <TextField
                            label="Select Date"
                            type="date"
                            value={selectedDate}
                            onChange={(e) => setSelectedDate(e.target.value)}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            sx={{ marginBottom: "20px" }}
                        />

                        <Box sx={{ display: 'flex', justifyContent: 'center', height: '500px' }}>
                            <Line data={dailyData} />
                        </Box>
                    </Paper>
                </Grid>

                {/* Daily Listeners Data Table */}
                <Grid item xs={12}>
                    <Paper elevation={3} sx={{ padding: "20px" }}>
                        <Typography variant="h5" gutterBottom>Daily Listeners Data</Typography>
                        <TableContainer style={{ maxHeight: "50vh" }}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Timestamp</TableCell>
                                        <TableCell>Listeners</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {dailyListenersData.map((row, index) => (
                                        <TableRow key={index} hover>
                                            <TableCell>{row.timestamp}</TableCell>
                                            <TableCell>{row.listeners}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>
                </Grid>
            </Grid>
        </Box>
    );
}

function getHebrewDays(dayInNumber) {
    switch (dayInNumber) {
        case 0:
            return "ראשון"
        case 1:
            return "שני"
        case 2:
            return "שלישי"
        case 3:
            return "רביעי"
        case 4:
            return "חמישי"
        case 5:
            return "שישי"
        case 6:
            return "שבת"
        default:
            return 'דודה שך'
    }
}