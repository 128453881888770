import React, { useEffect } from "react";

const Loader = ({ loading }) => {
  // Optionally, add any effect when the loading starts or stops
  useEffect(() => {
    if (loading) {
      console.log("Loading started...");
    } else {
      console.log("Loading finished.");
    }
  }, [loading]);

  return (
    <>
      {loading && (
        <div style={styles.loaderOverlay}>
          <div style={styles.loader}>Loading...</div>
        </div>
      )}
    </>
  );
};

// Styles for the loader overlay and spinner
const styles = {
  loaderOverlay: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 9999,
  },
  loader: {
    color: "#fff",
    fontSize: "24px",
    fontWeight: "bold",
  },
};

export default Loader;